<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Enter reject message') }}</h3>
                </div>
                <form @submit.prevent="">
                    <div class="form-group">
                        <textarea :class="['form-control', {'has-error': errors['message']}]" v-model="application.status_description" :placeholder="$t('Enter message')"></textarea>

                        <FormErrors v-if="errors[`message`]" :errors="errors[`message`]" />
                    </div>

                    <div class="form-group">
                        <Multiselect
                            v-model="application.rejected_reason_id"
                            :options="reasons"
                            :placeholder="$t('Select reason')"
                            :class="['form-control', {'has-error': errors['rejected_reason_id']}]"
                        />

                        <FormErrors v-if="errors[`rejected_reason_id`]" :errors="errors[`rejected_reason_id`]" />
                    </div>

                    <div class="form-group">
                        <button @click="rejectCandidate" class="btn btn-danger float-right">{{ $t('Reject') }}</button>
                    </div>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import { applicationService } from "@/services/applicationService";
import FormErrors from '@/components/widgets/FormErrors';
import Multiselect from '@vueform/multiselect'
import Modal from '@/components/widgets/Modal';

export default {
    name: "RejectApplicationComponent",
    data() {
        return {

        }
    },
    components: {
        FormErrors,
        Multiselect,
        Modal
    },
    props: ['application'],
    computed: {
        reasons() {
            return this.$store.getters.reasons
        }
    },
    methods: {
        rejectCandidate() {
            applicationService.reject(this.application.id, {
                message: this.application.status_description,
                rejected_reason_id: this.application.rejected_reason_id
            })
            .then(() => {
                alert('Success');
                this.$emit('closeModal');
            })
            .catch(error => {
                this.errors = error.response.data.errors || []
            })
        },
    }
}
</script>