<template>
    <div class="inner-view application-inner-view">
        <button class="btn icon-btn go-back" @click="this.$emit('switchActiveComponent', 'candidates')">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.829" height="21.451" viewBox="0 0 19.829 21.451">
                <g>
                    <g>
                        <path d="M6.118 1.032L2.8 4.349h8.477a8.6 8.6 0 0 1 8.552 8.551 8.653 8.653 0 0 1-8.551 8.551H1.917v-1.475h9.362a7.175 7.175 0 0 0 7.076-7.076 7.126 7.126 0 0 0-7.077-7.077H2.8L6.118 9.14l-1.032 1.032L0 5.086 5.086 0z" transform="translate(164) translate(-164)"/>
                    </g>
                </g>
            </svg>
        </button>
        <CandidateView :_id="application.candidate.id">
            <template v-slot:bottomButtons>
                <div class="item-footer-buttons d-flex align-items-center justify-content-between">
                    <div class="left-buttons">
                        <button class="btn btn-primary" @click="acceptApplication">{{ $t('Accept') }}</button>
                        <button class="btn btn-secondary" @click="showRejectModal = true">{{ $t('Reject') }}</button>
                        <button class="btn btn-secondary">{{ $t('Contact Recruiter') }}</button>
                    </div>
                    <div class="right-buttons">
                        <a class="text-btn">{{ $t('Report applicant') }}</a>
                    </div>
                </div>
            </template>
        </CandidateView>
        <transition name="fade">
            <RejectModal :application="application" v-if="showRejectModal" @closeModal="showRejectModal = false" />
        </transition>
    </div>
</template>

<script>
import CandidateView from "@/views/candidates/ViewCandidate";
import { applicationService } from "@/services/applicationService";
import RejectModal from "@/components/applications/modals/RejectApplicationComponent";

export default {
    props: ['application'],
    data() {
        return {
            showRejectModal: false
        }
    },
    components: {
        CandidateView,
        RejectModal
    },
    methods: {
        acceptApplication() {
            applicationService.approve(this.application.id).then(res => {
                alert('Success');
                console.log(res);
            }).catch(err => {
                alert('Error');
                console.log(err)
            })
        },
        back() {
            this.$emit('switchActiveComponent', 'applications')
        }
    }
}
</script>